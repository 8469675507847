/* src/fonts.css */
@font-face {
  font-family: "Bobby Jones";
  src: url("../public/fonts/BobbyJonesSoft.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Work Sans";
  src: url("../public/fonts/WorkSans-VariableFont_wght.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "Bobby Jones";
  src: url("../public/fonts/BobbyRoughSoft.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
} */
